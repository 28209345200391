/* eslint-disable react/require-default-props */
import React, { useId } from 'react';
import PropTypes from 'prop-types';
import { Popover } from '@headlessui/react';
import { QuestionMarkCircleIcon } from '@heroicons/react/solid';
import classNames from './_classNames';

export default function Input({
  value = '',
  onChange,
  label,
  type = 'text',
  textarea = false,
  suffix = '',
  info,
  className = '',
  placeholder = '',
  number = false,
  errorText = '',
  classInputName = '',
  onBlur = () => {},
  disabled = false,
  step,
  focusRef,
  onKeyDown,
  maxLength,
}) {
  const id = useId();
  const classError = errorText
    ? 'text-red-900 ring-red-300 placeholder:text-red-300 focus:ring-red-500'
    : '';
  const classDisabled = disabled
    ? 'disabled:cursor-not-allowed disabled:text-gray-500 disabled:bg-gray-50'
    : '';

  const handleChange = (e) => {
    const valueFm = e.target.value;
    return onChange(valueFm);
  };

  const displayInputElement = () => {
    if (suffix) {
      return (
        <div
          className={classNames(
            'flex rounded-md shadow-sm ring-1 ring-inset ring-gray-300',
            'focus-within:ring-2 focus-within:ring-inset focus-within:ring-blue-600 sm:max-w-md',
            classError,
            disabled && 'cursor-not-allowed bg-gray-50 text-gray-500',
          )}
        >
          <input
            ref={focusRef}
            id={id}
            value={value}
            onChange={handleChange}
            onBlur={onBlur}
            type={number ? 'number' : type}
            disabled={disabled}
            step={step}
            className={classNames(
              'block flex-1 w-20 border-0 bg-transparent py-1.5 pr-2 text-gray-900',
              'placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6 truncate',
              classInputName,
              classError,
              disabled && 'cursor-not-allowed bg-gray-50 text-gray-500',
            )}
            placeholder={placeholder}
            onKeyDown={onKeyDown}
            maxLength={maxLength}
          />
          <span
            className={classNames(
              'flex select-none items-center pr-3 text-gray-500 sm:text-sm',
              classError,
            )}
          >
            {suffix}
          </span>
        </div>
      );
    }

    if (textarea) {
      return (
        <textarea
          id={id}
          ref={focusRef}
          value={value}
          onChange={handleChange}
          onBlur={onBlur}
          rows={3}
          type={number ? 'number' : type}
          placeholder={placeholder}
          disabled={disabled}
          onKeyDown={onKeyDown}
          maxLength={maxLength}
          className={classNames(
            'block w-full rounded-md border-0 py-1.5 text-gray-900',
            'shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400',
            'focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
            classError,
            classDisabled,
          )}
        />
      );
    }

    return (
      <input
        id={id}
        ref={focusRef}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        type={number ? 'number' : type}
        placeholder={placeholder}
        disabled={disabled}
        onKeyDown={onKeyDown}
        maxLength={maxLength}
        className={classNames(
          'block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm',
          'ring-1 ring-inset ring-gray-300 placeholder:text-gray-400',
          'focus:ring-2 focus:ring-inset focus:ring-blue-600 sm:text-sm sm:leading-6',
          classInputName,
          classError,
          classDisabled,
        )}
      />
    );
  };

  return (
    <div className={className}>
      {label && (
        <label
          htmlFor={id}
          className={classNames(
            'block text-sm font-medium leading-6 text-gray-900',
          )}
        >
          {label}
        </label>
      )}
      <div className={label ? 'mt-2' : ''}>
        {displayInputElement()}
        {info && null && (
          <div className="absolute inset-y-0 right-0 pr-3 flex items-center">
            <Popover className="relative">
              <Popover.Button>
                <QuestionMarkCircleIcon className="h-5 w-5 text-gray-400" />
              </Popover.Button>

              <Popover.Panel className="absolute z-10">{info}</Popover.Panel>
            </Popover>
          </div>
        )}
      </div>
      {errorText && (
        <p className="mt-1 text-xs font-normal text-red-500">{errorText}</p>
      )}
    </div>
  );
}

Input.propTypes = {
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  onChange: PropTypes.func.isRequired,
  label: PropTypes.string,
  number: PropTypes.bool,
  type: PropTypes.oneOf([
    'date',
    'text',
    'number',
    'email',
    'datetime-local',
    'password',
  ]),
  textarea: PropTypes.bool,
  suffix: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  errorText: PropTypes.string,
  onBlur: PropTypes.func,
  disabled: PropTypes.bool,
};
