import moment from 'moment';
import displayValue from './displayValue';

export default function convertWaterTestsToGraphData(data, type) {
  let result = data;
  if (type === 'Level') {
    result = result
      .filter((row) => (!row.Data || !row.Data.No_Measurement))
      .map((row) => ({
        Date: moment(row.Test_Date).utc(false).valueOf(),
        value: displayValue(row.Data?.Measurement, { round: true }),
        Level_Type: row.Level_Type,
        Is_Dry: row.Data?.Is_Dry,
      }));
  } else {
    result = result.map((row) => {
      const sample = (row.Tests || [])
        .find((test) => (
          test.Type === 'Sample'
            && !test.No_Nitrate
            && !test.No_Sample_Taken
        ));

      return {
        Date: moment(row.Date).utc(false).valueOf(),
        value: displayValue(sample?.Nitrate, { round: true }),
      };
    });
  }

  return result.sort((a, b) => (a.Date < b.Date ? -1 : 1));
}
