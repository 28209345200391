import { useState, useEffect, useCallback } from 'react';
import request from '../utils/fetch';
import * as Sentry from '@sentry/react';

async function execute(url, setData, setLoading = () => {}) {
  try {
    let data = await request({ url });

    if (!data) data = [];

    if (data?.error) {
      Sentry.captureException(data.error);
    }
    if (data?.error === 'Authentication failed') {
      window.location.href = '/error?msg=unauthorized';
    }
    if (data?.error === 'Incorrect account config') {
      window.location.href = '/error?msg=incorrect_account_config';
    }

    setData(data);
    setLoading(false);
  } catch (error) {
    setData(null);
    setLoading(false);
    console.warn(error);
    Sentry.captureException(error);
  }
}

export default function useFetch(url, options = {}) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  const includeUrl = options.includeUrl || false;
  const updateData = useCallback((newData) => {
    if (includeUrl) setData({ ...newData, _url: url });
    else setData(newData);
  }, [url, includeUrl]);

  useEffect(() => {
    if (options.prevent) return;

    setLoading(true);
    execute(url, updateData, setLoading);
  }, [url, updateData, options.prevent]);

  function refresh(refreshedData) {
    if (refreshedData) {
      updateData(refreshedData);
    } else {
      setLoading(true);
      execute(url, updateData, setLoading);
    }
  }

  return { data, refresh, loading };
}

export function useLazyFetch(url) {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);

  return [
    (urlSuffix, omitSlash = false) => {
      if (urlSuffix && !omitSlash && urlSuffix[0] !== '/') urlSuffix = `/${urlSuffix}`
      else if (omitSlash && urlSuffix[0] === '/') {
        urlSuffix = urlSuffix.substring(1);
      };
      setLoading(true);
      execute(urlSuffix ? url + urlSuffix : url, setData, setLoading);
      setData(null);
    },
    {
      data,
      loading
    },
  ];
}
