import roundTo from './roundTo';

// Get Data to display into the Popup
const getDataWithType = (type, properties) => {
  switch (type) {
    case 'Flowmeter':
      return {
        Meter_Location: properties['Meter_Location'] || '',
        Serial_num: properties['Serial_num'] || '',
      }
    case 'Well':
      return {
        Well_Type: properties['Well_Type'] || '',
        Reg_No: properties['Reg_No'] || '',
      }
    default:
      return null
  }
}

const getYearOptions = (startYear = 2018) => {
  const yearOptions = [];
  const currentYear = new Date().getFullYear() + 1;
  for (let i = startYear; i <= currentYear && startYear < currentYear; i++) {
    yearOptions.push(i);
  }
  
  return yearOptions;
}

const parseFloatPercentage = (val) => (val && !isNaN(val) ? roundTo(100 * Number(val), 10).toString() : '');

const delaySeconds = async (secs) => {
  await new Promise((resolve) => {
    setTimeout(resolve, secs * 1000);
  });
}

const upperCaseFirstLetter = (string) => string.charAt(0).toUpperCase() + string.slice(1);

const randomId = () => {
  return (Math.random() * 10 ** 17).toString();
}

export {
  getYearOptions,
  getDataWithType,
  parseFloatPercentage,
  delaySeconds,
  upperCaseFirstLetter,
  randomId,
}