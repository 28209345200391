import moment from 'moment';
import roundTo from './roundTo';
import { parseFloatPercentage } from './helpers';

// returns a displayable value
export default function displayValue(value, options = {}, emptyPlaceholder) {
  if (value || value === 0) {
    if (options.date) return moment(value).utc(false).format('MM/DD/YYYY');
    if (options.year) return moment(value).year();
    if (options.round) return roundTo(value, 2);
    if (options.percent) return parseFloatPercentage(value);
    if (options.dateTimeLocal) return moment(value).local().format('MM/DD/YYYY HH:mm');
    return value;
  }

  if (typeof options.fallback === 'string') return options.fallback;

  return emptyPlaceholder || 'N/A';
}
