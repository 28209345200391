import React, { useEffect } from 'react';
import Graphic from '@arcgis/core/Graphic';
import TextSymbol from '@arcgis/core/symbols/TextSymbol';
import Polygon from '@arcgis/core/geometry/Polygon';
import FeatureLayer from '@arcgis/core/layers/FeatureLayer';
import GroupLayer from '@arcgis/core/layers/GroupLayer';
import Point from '@arcgis/core/geometry/Point';
import SimpleFillSymbol from '@arcgis/core/symbols/SimpleFillSymbol';
import UniqueValueRenderer from '@arcgis/core/renderers/UniqueValueRenderer';
import { createRoot } from 'react-dom/client';
import iconUrls from '../map/iconUrls';
import request from '../../utils/fetch';
import MapPopupContentLevels from '../../components/esriMap/MapPopupContentLevels';

const layers = ['wells-group', 'flowmeters', 'chemigations', 'soils', 'tracts-group'];

function tractBgColor(type) {
  if (type === 'Certified Acres') {
    return [219, 171, 245, 0.4];
  }
  else if (type === 'Reporting Tract'){
    return [255, 166, 70, 0.5];
  }
  return [0, 255, 0, 0.4];
}

function applyEditLayers(existingLayer, source, keyToCompare = '_id') {
  existingLayer.queryFeatures().then((result) => {
    const existingIds = new Set((result?.features || []).map((f) => f.attributes[keyToCompare]));
    const sourceIds = new Set(source.map((f) => f.attributes[keyToCompare]));

    // Separate features into new (to add) and existing (to update)
    const newFeatures = source.filter((f) => !existingIds.has(f.attributes[keyToCompare]));
    const existingFeatures = source.filter((f) => existingIds.has(f.attributes[keyToCompare]));
    const deleteFeatures = result.features.filter((f) => !sourceIds.has(f.attributes[keyToCompare]));

    // Apply edits: Add new features and update existing features
    existingLayer
      .applyEdits({
        addFeatures: newFeatures,
        // updateFeatures: existingFeatures,
        deleteFeatures,
      });
  });
}

export default function useEsriMapLayersSetup({
  certGeo,
  irrigatedGeo,
  pointGeom,
  graphicsLayerRef,
  mapRef,
  alwaysShowIcons,
  labelWellsWithRegNo,
  townshipsGeo,
  sectionsGeo,
  printable,
  showLevelsInPopup,
}) {
  const renderer = {
    type: 'simple',
    symbol: {
      type: 'simple-fill',
      color: 'rgba(0, 0, 0, 0)',
      outline: {
        color: '#ddd',
        width: 2,
      },
    },
    labelingInfo: [
      {
        symbol: new TextSymbol({
          color: '#eee',
        }),
        labelPlacement: 'always-horizontal',
        labelExpressionInfo: {
          expression: '$feature.displayId',
        },
        deconflictionStrategy: 'none',
        priority: 1, // Lower priority than other layers
        removeOverlappingLabels: true,
        removeUnplacedLabels: true,
      },
    ],
  };

  useEffect(() => {
    if ((sectionsGeo || townshipsGeo) && graphicsLayerRef.current && mapRef.current) {
      const existingGroupLayer = mapRef.current.findLayerById('PLSS-GroupLayer');

      const createFeatureLayer = (id, title, features, renderer, fields, labelingInfo, minScale, maxScale) => new FeatureLayer({
        id,
        source: features,
        title,
        renderer,
        objectIdField: 'ObjectID',
        fields,
        labelingInfo,
        minScale,
        maxScale,
        editingEnabled: printable,
      });

      // Create section features
      const sectionFeatures = (sectionsGeo?.features || []).map((sec) => {
        const polygon = new Polygon({
          rings: sec.geometry.coordinates[0],
        });
        return {
          geometry: polygon,
          attributes: {
            type: 'sections',
            displayId: sec.properties.displayId || '',
          },
        };
      });

      // Create township features
      const townshipFeatures = (townshipsGeo?.features || []).map((sec, index) => {
        const polygon = new Polygon({
          rings: sec.geometry.coordinates[0],
        });
        return {
          geometry: polygon,
          attributes: {
            ObjectID: index + 1,
            type: 'townships',
            displayId: sec.properties.displayId || '',
          },
        };
      });

      if (existingGroupLayer) {
        if (printable) {
          // Update both layers inside the GroupLayer
          const sectionLayer = existingGroupLayer.findLayerById('Sections');
          const townshipLayer = existingGroupLayer.findLayerById('Townships');

          if (sectionLayer) {
            sectionLayer.queryFeatures().then((result) => {
              if (!result?.features?.length) {
                existingGroupLayer.layers.removeAt(0);
                existingGroupLayer.layers.add(createFeatureLayer(
                  'Sections',
                  'PLSS - Sections',
                  sectionFeatures,
                  renderer,
                  [
                    { name: 'ObjectID', alias: 'ObjectID', type: 'oid' },
                    { name: 'displayId', alias: 'Display ID', type: 'string' },
                  ],
                  [
                    {
                      symbol: new TextSymbol({
                        color: '#eee',
                        font: { size: 14, family: 'Arial' },
                      }),
                      labelPlacement: 'always-horizontal',
                      labelExpressionInfo: { expression: '$feature.displayId' },
                      minScale: 72224,
                      deconflictionStrategy: 'static',
                      priority: 1,
                      removeOverlappingLabels: true,
                      removeUnplacedLabels: true,
                    },
                  ],
                  72224,
                  null,
                ), 0);
              } else {
                applyEditLayers(sectionLayer, sectionFeatures, 'displayId');
              }
            });
          }
          if (townshipLayer) {
            applyEditLayers(townshipLayer, townshipFeatures, 'displayId');
          }
        }
      } else {
        // Create the Section and Township layers
        const sectionLayer = createFeatureLayer(
          'Sections',
          'PLSS - Sections',
          sectionFeatures,
          renderer,
          [
            { name: 'ObjectID', alias: 'ObjectID', type: 'oid' },
            { name: 'displayId', alias: 'Display ID', type: 'string' },
          ],
          [
            {
              symbol: new TextSymbol({
                color: '#eee',
                font: { size: 14, family: 'Arial' },
              }),
              labelPlacement: 'always-horizontal',
              labelExpressionInfo: { expression: '$feature.displayId' },
              minScale: 72224,
              deconflictionStrategy: 'static',
              priority: 1,
              removeOverlappingLabels: true,
              removeUnplacedLabels: true,
            },
          ],
          72224,
          null,
        );

        const townshipLayer = createFeatureLayer(
          'Townships',
          'PLSS - Townships',
          townshipFeatures,
          renderer,
          [
            { name: 'ObjectID', alias: 'ObjectID', type: 'oid' },
            { name: 'displayId', alias: 'Display ID', type: 'string' },
          ],
          [
            {
              symbol: new TextSymbol({
                color: '#eee',
                font: { size: 14, family: 'Arial' },
              }),
              labelPlacement: 'always-horizontal',
              labelExpressionInfo: { expression: '$feature.displayId' },
              minScale: 288895,
              maxScale: 72224,
              deconflictionStrategy: 'static',
              priority: 1,
              removeOverlappingLabels: true,
              removeUnplacedLabels: true,
            },
          ],
          577791,
          72224,
        );

        // Create the GroupLayer
        const groupLayer = new GroupLayer({
          id: 'PLSS-GroupLayer',
          title: 'PLSS',
          layers: [sectionLayer, townshipLayer],
        });

        // Add the GroupLayer to the map
        mapRef.current.add(groupLayer);

        // Reorder the GroupLayer to the top
        layers.forEach((layer) => {
          const existingLayer = mapRef.current.findLayerById(`${layer}-layer`);
          if (existingLayer) {
            mapRef.current.reorder(existingLayer, mapRef.current.layers.indexOf(groupLayer) + 1);
          }
        });
      }
    }
  }, [graphicsLayerRef, sectionsGeo, townshipsGeo, renderer, mapRef]);

  useEffect(() => {
    if (certGeo && graphicsLayerRef.current) {
      let filteredCertFeatures = [];

      if (certGeo.features && certGeo.features.length) {
        filteredCertFeatures = certGeo.features;
      } else if (certGeo.properties) {
        filteredCertFeatures = [{ ...certGeo }];
      }

      // Create features for FeatureLayer
      const features = filteredCertFeatures
        .map((cf, index) => {
          const polygons = (cf?.geometry?.coordinates || [[]]).map((coord, idx) => ({
            geometry: new Polygon({
              rings: coord,
            }),
            attributes: {
              ObjectID: `${index}-${idx}`, // Unique identifier for each feature
              type: 'certs',
              displayId: cf.properties.displayId || '',
              category: cf.properties.Category || '',
              _id: cf.properties._id || '',
            },
          }));

          return polygons;
        })
        .flat()
        .reduce((result, item) => {
          if (!result[item.attributes.category]) {
            result[item.attributes.category] = [];
          }
          result[item.attributes.category].push(item);
          return result;
        }, {}); // Flatten in case of multiple coordinates

      // Define the renderer for the layer
      const renderer = new UniqueValueRenderer({
        field: 'category', // Category field to drive the color
        uniqueValueInfos: filteredCertFeatures.map((cf) => ({
          value: cf.properties.Category, // Value of the category
          symbol: new SimpleFillSymbol({
            color: tractBgColor(cf.properties.Category), // Dynamic color
            outline: {
              color: '#af53e0',
              width: 2,
            },
          }),
          label: cf.properties.Category, // Label for the legend, optional
        })),
      });

      // Create a feature layer with the polygons
      const newLayers = [];
      Object.entries(features).forEach(([key, value]) => {
        const existingLayer = mapRef.current.findLayerById(
          `certs-${key}`,
        );
        if (existingLayer) {
          applyEditLayers(existingLayer, value);
        } else {
          const certLayer = new FeatureLayer({
            id: `certs-${key}`, // Unique ID per category
            title: `Tracts - ${key}`,
            source: value,
            renderer,
            objectIdField: 'ObjectID', // Field for unique ObjectID
            fields: [
              {
                name: 'ObjectID',
                alias: 'ObjectID',
                type: 'oid',
              },
              {
                name: 'displayId',
                alias: 'Display ID',
                type: 'string',
              },
              {
                name: 'category',
                alias: 'Category',
                type: 'string',
              },
              {
                name: '_id',
                type: 'string',
              },
            ],
            outFields: ['_id', 'displayId', 'category'],
            minScale: 72224,
            labelingInfo: [
              {
                symbol: new TextSymbol({
                  color: 'black',
                  font: {
                    size: 14,
                    family: 'Arial',
                  },
                }),
                labelPlacement: 'always-horizontal',
                labelExpressionInfo: {
                  expression: '$feature.displayId',
                },
                minScale: 18056,
                deconflictionStrategy: 'static',
                priority: 0,
                removeOverlappingLabels: true,
                removeUnplacedLabels: true,
              },
            ],
            zIndex: 0,
            popupTemplate: {
              title: 'Tract ID: {displayId}', // Customize title using field attributes
              content: (feature) => {
                const div = document.createElement('div');
                div.innerHTML = '<p>Loading...</p>';
                request({ url: `certs/${feature.graphic.attributes._id}?mapPopup=true` })
                  .then((data) => {
                    div.innerHTML = `${Object.entries(data)
                      .map(([key, val]) => {
                        if (
                          !val
                    || typeof val === 'object'
                    || Array.isArray(val)
                        ) {
                          return ''; // Skip invalid or complex data
                        }
                        return `<p><strong>${key
                          .split('_')
                          .join(' ')}:</strong> ${val}</p>`;
                      })
                      .join('')}<a href="${
                      window.location.origin
                    }/certs/${
                      feature.graphic.attributes._id
                    }" target="_blank" rel="noopener noreferrer">Go To Tract Page >></a>`;
                  })
                  .catch((error) => {
                    // Handle any errors during the fetch
                    console.error('Error fetching data:', error);
                    div.innerHTML = '<p></p>';
                  });
                return div;
              },
            },
          });

          newLayers.push(certLayer);
        }
      });

      if (newLayers.length) {
        const groupLayer = new GroupLayer({
          id: 'tracts-group-layer',
          title: 'Tracts',
          layers: newLayers,
        });
        mapRef.current.add(groupLayer);
        mapRef.current.reorder(groupLayer, 0);
      }
    }
  }, [graphicsLayerRef, certGeo]);

  useEffect(() => {
    if (irrigatedGeo && graphicsLayerRef.current) {
      irrigatedGeo.features.forEach((ig) => {
        // Create the polygon graphic
        const polygonGraphic = new Graphic({
          geometry: {
            type: 'polygon', // autocasts as new Polygon()
            rings: ig.geometry.coordinates,
          },
          symbol: {
            type: 'simple-fill', // autocasts as new SimpleFillSymbol()
            color: [138, 235, 145, 0.4],
            outline: {
              color: '#20d62c',
              width: 2,
            },
          },
          attributes: {
            type: 'irrigated',
          },
        });
        // Add the polygon graphic to the graphics layer
        graphicsLayerRef.current.add(polygonGraphic);
      });
    }
  }, [graphicsLayerRef, irrigatedGeo]);

  const createLayer = (type, layerTitle, features) => {
    const existingLayer = mapRef.current.findLayerById(`${type}-layer`);

    const source = features.map((item) => ({
      geometry: new Point({
        longitude: item?.geometry?.coordinates?.[0],
        latitude: item?.geometry?.coordinates?.[1],
      }),
      attributes: item.properties,
    }));
    const editingEnabled = printable || !!source[0].attributes.editable;

    if (existingLayer) {
      if (!editingEnabled) {
        return null;
      }
      applyEditLayers(existingLayer, source);
    } else {
      return new FeatureLayer({
        id: `${type}-layer`,
        title: layerTitle,
        source,
        objectIdField: 'id',
        editingEnabled,
        fields: [
          { name: 'id', type: 'oid' },
          { name: 'displayId', type: 'string' },
          { name: 'type', type: 'string' },
          { name: 'iconType', type: 'string' },
          { name: 'Reg_No', type: 'string' },
          { name: 'DNR_Well_ID', type: 'string' },
          { name: 'Well_Type', type: 'string' },
          { name: 'category', type: 'string' },
          { name: '_id', type: 'string' },
          { name: 'Meter_Location', type: 'string' },
          { name: 'Serial_num', type: 'string' },
        ],
        outFields: ['_id', 'displayId', 'type', 'Reg_No', 'DNR_Well_ID', 'Well_Type', 'Meter_Location', 'Serial_num'],
        renderer: {
          type: 'unique-value',
          field: 'iconType',
          uniqueValueInfos: Object.entries(iconUrls).map(([key, value]) => ({
            value: key,
            symbol: {
              type: 'picture-marker',
              url: value,
              width: 24,
              height: 24,
            },
          })),
        },

        popupTemplate: {
          title: (feature) => {
            const { type } = feature.graphic.attributes;
            if (type === 'Well') {
              if (feature.graphic.attributes.displayId) {
                return `Well NRD ID: ${feature.graphic.attributes.displayId}`;
              }
              if (feature.graphic.attributes.Reg_No) {
                return `Well Reg No: ${feature.graphic.attributes.Reg_No}`;
              }
              if (feature.graphic.attributes.DNR_Well_ID) {
                return `Well DNR No: ${feature.graphic.attributes.DNR_Well_ID}`;
              }
            } else {
              return `${type} ID: ${feature.graphic.attributes.displayId}`;
            }
          },
          content: async (feature) => {
            const {
              type, _id, Meter_Location, Serial_num, Well_Type, Reg_No, DNR_Well_ID, displayId,
            } = feature.graphic.attributes;
            const linkToDetail = _id
              ? `<a href="${
                window.location.origin
              }/${type.toLowerCase()}s/${
                _id
              }" target="_blank" rel="noopener noreferrer">Go To ${
                type
              } Page >></a>`
              : '';
            if (type === 'Flowmeter') {
              return `
                <div>
                  <p><strong>Meter Location:</strong> ${
  Meter_Location || ''
}</p>
                  <p><strong>Serial No:</strong> ${
  Serial_num || ''
}</p>
${linkToDetail}
              </div>
            `;
            }
            if (type === 'Well') {
              if (showLevelsInPopup) {
                const container = document.createElement('div');
                const root = createRoot(container);
                root.render(<MapPopupContentLevels id={_id} displayId={displayId} />);
                return container;
              }
              return `
                <div>
                  <p><strong>Well Type:</strong> ${
  Well_Type || ''
}</p>
                <p><strong>Reg No:</strong> ${Reg_No || ''}</p>
                <p><strong>DNR ID:</strong> ${DNR_Well_ID || ''}</p>
                ${linkToDetail}
              </div>
            `;
            }
            if (type === 'Soils') {
              return `
                    <div>
                      <p><strong>ID:</strong> ${_id || ''}</p>
                      <p><strong>Soil Location ID:</strong> ${
  displayId || ''
}</p>
${linkToDetail}
                  </div>
                `;
            }
            if (type === 'Chemigation') {
              return `
                  <div>
                    <p><strong>Name:</strong> ${displayId || ''}</p>
                    ${linkToDetail}
                  </div>
                `;
            }
            return null;
          },
        },
        zIndex: 999,
        minScale: 72224,
        ...(alwaysShowIcons && labelWellsWithRegNo
          ? {
            labelingInfo: [
              {
                symbol: new TextSymbol({
                  color: '#dddddd',
                  font: {
                    size: 10,
                    family: 'Arial',
                  },
                }),
                labelPlacement: 'below-center',
                labelExpressionInfo: {
                  expression: '$feature.displayId',
                },
              },
            ],
          }
          : {
            featureReduction: {
              type: 'cluster',
              clusterRadius: '40px',
              clusterMinSize: '24px',
              clusterMaxSize: '40px',
              symbol: {
                type: 'simple-marker',
                style: 'circle',
                color: 'rgb(44,169,224)',
                outline: {
                  color: 'rgb(44,169,224)',
                  width: 2,
                },
              },
              labelingInfo: [
                {
                  deconflictionStrategy: 'none',
                  labelExpressionInfo: {
                    expression: "Text($feature.cluster_count, '#,###')",
                  },
                  symbol: {
                    type: 'text',
                    color: '#dddddd',
                    font: {
                      weight: 'bold',
                      family: 'Noto Sans',
                      size: '14px',
                    },
                  },
                  labelPlacement: 'center-center',
                },
              ],
            },
          }),
      });
    }
  };

  useEffect(() => {
    if (mapRef.current) {
      const newLayers = [];
      if (pointGeom?.irrWells?.length) {
        const layer = createLayer('irrWells', 'Irrigation Wells', pointGeom.irrWells);
        if (layer) newLayers.push(layer);
      }
      if (pointGeom?.monWells?.length && mapRef.current) {
        const layer = createLayer('monWells', 'Monitoring Wells', pointGeom.monWells);
        if (layer) newLayers.push(layer);
      }
      if (pointGeom?.miWells?.length && mapRef.current) {
        const layer = createLayer('miWells', 'M&I Wells', pointGeom.miWells);
        if (layer) newLayers.push(layer);
      }
      if (pointGeom?.wells?.length && mapRef.current) {
        const layer = createLayer('wells', 'Other Wells', pointGeom.wells);
        if (layer) newLayers.push(layer);
      }
      if (newLayers.length) {
        const existingGroupLayer = mapRef.current.findLayerById('wells-group-layer');

        if (existingGroupLayer) {
        // Clear the old layers and add the new layers
          existingGroupLayer.layers.removeAll();
          existingGroupLayer.addMany(newLayers);
        } else {
        // Create a new GroupLayer if it doesn't exist
          const groupLayer = new GroupLayer({
            id: 'wells-group-layer', // Ensure this ID is unique to find it later
            title: 'Wells',
            layers: newLayers,
          });

          // Add the GroupLayer to the map
          mapRef.current.add(groupLayer);
        }
      }
    }
  }, [pointGeom?.irrWells, pointGeom?.monWells, pointGeom?.miWells, pointGeom?.wells, mapRef.current]);

  useEffect(() => {
    if (pointGeom?.flowmeters?.length && mapRef.current) {
      const layer = createLayer('flowmeters', 'Flowmeters', pointGeom.flowmeters);
      if (layer) mapRef.current.add(layer);
    }
  }, [pointGeom?.flowmeters, mapRef.current]);

  useEffect(() => {
    if (pointGeom?.chemigations?.length && mapRef.current) {
      const layer = createLayer('chemigations', 'Chemigations', pointGeom.chemigations);
      if (layer) mapRef.current.add(layer);
    }
  }, [pointGeom?.chemigations, mapRef.current]);

  useEffect(() => {
    if (pointGeom?.soils?.length && mapRef.current) {
      const layer = createLayer('soils', 'Soil Samples', pointGeom.soils);
      if (layer) mapRef.current.add(layer);
    }
  }, [pointGeom?.soils, mapRef.current]);

  const removeUntitledLayers = (map) => {
    const untitledLayers = map.layers.items.filter((layer) => !layer.title || layer.title.trim() === '');

    // Remove each untitled layer
    untitledLayers.forEach((layer) => map.remove(layer));
  };

  useEffect(() => {
    if (mapRef.current) {
      // Call the function to remove untitled layers
      removeUntitledLayers(mapRef.current);
    }
  }, [mapRef.current]);
}