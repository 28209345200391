import React from 'react';
import Spinner from '../common/2.0/Spinner';
import WellsLineChart from '../well/WellsLineChart';
import useFetch from '../../hooks/useFetch';
import convertWaterTestsToGraphData from '../../utils/convertWaterTestsToGraphData';
import '../../assets/scss/MapPopupContentLevels.scss';

export default function MapPopupContentLevels({ id, displayId }) {
  const { data } = useFetch(`public/wellLevels/${id}`);

  return (
    <div className="MapPopupContentLevel">
      <h3>Well {displayId}</h3>
      {data
        ? (
          <div id="Level-chart" className="w-100">
            <WellsLineChart
              data={convertWaterTestsToGraphData(data, 'Level')}
              type="Level"
              hideYAxisLabel
            />
          </div>
        )
        : <div className="pt-2 ml-5 h-[300px]"><Spinner /></div>}
    </div>
  );
}