/* eslint-disable react/require-default-props */
import React from 'react';
import PropTypes from 'prop-types';
import { twMerge } from 'tailwind-merge';

export default function Button({
  onClick, children, className = '', disabled = false, type = '', style, ...restProps
}) {
  let typeClassName = '';
  if (type === 'transparent') {
    typeClassName = 'bg-transparent text-gray-600';
  } else if (type === 'outline') {
    typeClassName = 'border border-gray-400 text-gray-900';
  } else {
    typeClassName = 'bg-dark-blue';
  }

  return (
    <button
      type="button"
      className={twMerge(
        'inline-flex items-center justify-center px-10 py-3 rounded-sm text-sm font-medium text-white focus:outline-none disabled:opacity-60',
        typeClassName, className,
      )}
      onClick={onClick}
      disabled={disabled}
      style={style}
      {...restProps}
    >
      {children}
    </button>
  );
}

Button.propTypes = {
  onClick: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  type: PropTypes.string,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};
