import React from 'react';
import moment from 'moment';
import {
  LineChart, Line, XAxis, YAxis, Tooltip, CartesianGrid,
} from 'recharts';
import useChartWidth from '../../hooks/useChartWidth';
import displayValue from '../../utils/displayValue';
import roundTo from '../../utils/roundTo';

const CustomizedDot = ({
  cx, cy, width, height, payload,
}) => (
  payload?.Is_Dry
    ? (
      <>
        <line
          x1={cx - 3}
          y1={cy - 3}
          x2={cx + 3}
          y2={cy + 3}
          stroke="#376eed"
          strokeWidth="2"
        />
        <line
          x1={cx - 3}
          y1={cy + 3}
          x2={cx + 3}
          y2={cy - 3}
          stroke="#376eed"
          strokeWidth="2"
        />
      </>
    )
    : (
      <circle
        r="3"
        stroke="#376eed"
        fill="#fff"
        width={width}
        height={height}
        className="recharts-dot recharts-line-dot"
        cx={cx}
        cy={cy}
      />
    )
);

const CustomizedLabel = ({
  x, y, stroke, value,
}) => (
  <text x={x} y={y} dy={-4} fill={stroke} fontSize={10} textAnchor="middle">
    {value}
  </text>
);

export default function WellsLineChart({
  data, type, showLevelValues, idSuffix, disableAnimations, height, hideYAxisLabel,
}) {
  const id = `${type}-chart${idSuffix ? `-${idSuffix}` : ''}`;
  const width = useChartWidth(id);

  return (
    <div id={id} className="w-100">
      <LineChart
        width={width}
        height={height || (showLevelValues ? 450 : 200)}
        data={data}
        margin={{
          top: 20, right: 10, left: 10, bottom: 10,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" horizontal={false} />
        <Line
          type="monotone"
          dataKey="value"
          stroke="#376eed"
          dot={showLevelValues || <CustomizedDot />}
          label={showLevelValues && <CustomizedLabel />}
          isAnimationActive={!disableAnimations}
        />
        <XAxis
          dataKey="Date"
          type="number"
          scale="time"
          tickCount={3}
          tickSize={10}
          domain={['auto', 'auto']}
          tickFormatter={(el) => moment(el).utc(false).format('YYYY')}
        />
        <YAxis
          domain={['dataMin', 'dataMax']}
          tickSize={8}
          tickFormatter={(el) => roundTo(el, 1)}
          label={!hideYAxisLabel && {
            value: type === 'Level' ? 'Feet' : 'Nitrate Measurements ppm',
            angle: -90,
            position: type === 'Level' ? 'insideLeft' : 'insideBottomLeft',
            fontSize: type === 'Quality' ? 9 : 11,
          }}
          reversed={type === 'Level'}
        />
        <Tooltip
          formatter={(el) => [`${el} ${type === 'Level' ? 'Feet' : 'PPM'}`]}
          labelFormatter={(el) => displayValue(el, { date: true })}
        />
      </LineChart>
      {type === 'Level' && data.findIndex((el) => el.Is_Dry) !== -1 && (
        <svg height="24" width={width}>
          <CustomizedDot
            cx={width - 60}
            cy={9}
            width="10"
            height="10"
            payload={{ Is_Dry: true }}
          />
          <text x={width - 50} y="14">= Dry</text>
        </svg>
      )}
    </div>
  );
}
