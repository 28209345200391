import { useState, useEffect, useReducer } from 'react';

export default function useChartWidth(containerId, maxWidth) {
  const [width, setWidth] = useState(null);
  const [ticker, forceUpdate] = useReducer((x) => x + 1, 0);

  useEffect(() => {
    window.onresize = forceUpdate;
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      setWidth(
        document.getElementById(containerId)
          ? document.getElementById(containerId).offsetWidth
          : 500
      );
    }, 33);
    setTimeout(() => clearInterval(interval), 100);
  }, [containerId, maxWidth, ticker]);

  return maxWidth ? Math.min(width, maxWidth) : width;
}
