import { fetchAuthSession, fetchUserAttributes } from 'aws-amplify/auth';
import store from '../store';
import { setCurrentUser } from '../actions/userActions';
import { getSettings } from '../actions/settingsActions';

export const checkSignIn = async (callback) => {
  try {
    const session = await fetchAuthSession({ forceRefresh: true });
    const attributes = await fetchUserAttributes();

    // get settings
    store.dispatch(getSettings());

    // sign in on the front end
    const { accessToken } = session.tokens;
    if (!accessToken.payload['cognito:groups']) {
      accessToken.payload['cognito:groups'] = [];
    }
    const permission = accessToken.payload['cognito:groups'].includes(
      'permission',
    );
    const groups = accessToken.payload['cognito:groups'].filter(
      (el) => el !== 'permission',
    );
    store.dispatch(setCurrentUser({
      ...attributes,
      permission,
      groups,
    }));
  } catch (error) {
    console.log('error:', error.message);
  } finally {
    if (callback) callback();
  }
}

export const getAccessToken = async () => {
  try {
    const session = await fetchAuthSession();
    return session?.tokens?.accessToken?.toString();
  } catch (error) {
    console.log('Error:', error);
    return null;
  }
}
